import { version } from '../../package.json';
import { Environment } from './environment.config';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment: Environment = {
// 	production: false, // to ensure ngsw to be configured.
// 	firebase: {
// 		apiKey: 'AIzaSyDnkAyX32VfsbwdyHH4CZlsqKmcJiSIKcM',
// 		authDomain: 'staging.ratiomonitor.app',
// 		databaseURL: 'https://ratiomonitoring-507-staging.firebaseio.com',
// 		projectId: 'ratiomonitoring-507-staging',
// 		storageBucket: 'ratiomonitoring-507-staging.appspot.com',
// 		messagingSenderId: '1067481070329',
// 		appId: '1:1067481070329:web:e7e57e1b0be5f35440c028',
// 	},
// 	apiEndpoint: 'https://staging.api.ratiomonitor.app',
// 	// apiEndpoint: 'http://localhost:8080',
// 	version,
// 	name: 'STAGING',
// };

export const environment: Environment = {
	production: false,
	firebase: {
		apiKey: 'AIzaSyBUPNgnbpAssckiPQkwZSB_rY8xSIp-XAE',
		authDomain: 'ratiomonitor.app',
		databaseURL: 'https://ratiomonitoring.firebaseio.com',
		projectId: 'ratiomonitoring',
		storageBucket: 'ratiomonitoring.appspot.com',
		messagingSenderId: '508307314688',
		appId: '1:508307314688:web:1cb494bea3e2fa04',
	},
	apiEndpoint: 'https://prod.api.ratiomonitor.app',
	version,
	name: '',
};

export const FS_COLLECTIONS = {
	PROJECTS: 'projects',
	SENSORS: 'sensors',
	ALARMS: 'alarms',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
